ul,
li {
  list-style: none;
  padding: 0;
  margin: 0;
  font-family: "Roboto";
  font-weight: 400;
  font-size: 24px;
  color: #fcfcfc;
}

ul {
  position: relative;
  width: 900px;
  padding-top: 20px;
}

